import AppConfigurationService from '../app-configuration-service/app-configuration-service';
import { consentService } from '../consent-service/consent-service';
import { cafexService } from '../cafex-service/cafex-service';
import { BrandUtil } from '../../components/utils/brand-util/brand-util';
import AuthenticationService from '../authentication-service/authentication-service';
import { SOPHUS_CHAT_COUNTRIES } from '../../constants';

class ScriptService {
    public setMouseflowScript(removeMouseflowEleIfPresent = false) {
        const appConfiguration = new AppConfigurationService().getAppConfiguration();

        const medalliaMouseflowIntegration = () => {
            const win = window as any;
            const isMedalliaMouseflowSessionUnavailable = !win.medallia
                ?.mouseflowSessionId;
            if (isMedalliaMouseflowSessionUnavailable) {
                const mouseflowInterval = setInterval(() => {
                    const mouseflow = win.mouseflow;
                    if (mouseflow?.getSessionId()) {
                        console.debug(
                            'setting mouseflowSessionId for Medallia as ',
                            mouseflow.getSessionId()
                        );
                        win.medallia = win.medallia || {};
                        win.medallia.mouseflowSessionId = mouseflow.getSessionId();
                        clearInterval(mouseflowInterval);
                    }
                }, 100);
            }
        };

        if (appConfiguration.mouseflowId) {
            console.debug('Mouseflow initialised');
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = 'mouseflowId';
            script.defer = true;
            script.src = `//cdn.mouseflow.com/projects/${appConfiguration.mouseflowId}.js`;
            script.async = true;
            if (
                consentService.nonEssentialScriptsAllowed() &&
                !removeMouseflowEleIfPresent
            ) {
                script.setAttribute('class', 'optanon-category-6');
                document.body.appendChild(script);
                document.getElementsByTagName('body')[0].appendChild(script);
                medalliaMouseflowIntegration();
            } else if (removeMouseflowEleIfPresent) {
                const mouseflowEle = document.getElementById('mouseflowId');
                mouseflowEle &&
                    document
                        .getElementsByTagName('body')[0]
                        .removeChild(mouseflowEle);
            }
        } else console.debug('No Mouseflow configuration found');
    }

    public loadFMA(applicationId: string) {
        const appConfigurationService = new AppConfigurationService();
        const appConfiguration = appConfigurationService.getAppConfiguration();
        const origin = `${window.location.protocol}//${window.location.hostname}${appConfiguration.root}`;
        const fmaTag = document.createElement('script');
        const brand = new BrandUtil().getBrandName(appConfiguration.brand);
        const propertyKey =
            appConfiguration.fmaRegion == 'eu'
                ? 'eu_dashboard'
                : `global_owner_${brand}`;

        fmaTag.src = appConfiguration.fmaUrl;
        fmaTag.setAttribute('data-enable-guest-guid', 'false');
        fmaTag.setAttribute('data-fma-script', '');
        fmaTag.setAttribute(
            'data-ibm-cid',
            process.env.REACT_APP_IBM_CID || ''
        );
        fmaTag.setAttribute('data-property-key', propertyKey);
        fmaTag.setAttribute('data-region', appConfiguration.fmaRegion);
        fmaTag.setAttribute('data-fs-url', appConfiguration.apimUrl);
        fmaTag.setAttribute('data-logout-url', origin);
        fmaTag.setAttribute('data-origin-url', '');
        fmaTag.setAttribute(
            'data-lang',
            appConfiguration.languageRegionCode.split('-').join('_')
        );
        if (process.env.REACT_APP_B2C_FLAG === 'true') {
            fmaTag.setAttribute(
                'data-client-id',
                process.env.REACT_APP_AZURE_B2C_ID || ''
            );
            // fmaTag.setAttribute(
            //     'data-base-path',
            //     appConfigurationService.rootToUse(
            //         appConfiguration.root,
            //         appConfiguration.brand,
            //         appConfiguration.languageRegionCode
            //     )
            // );
            // fmaTag.setAttribute(
            //     'data-base-path-redirection',
            //     (
            //         appConfiguration.brand &&
            //         appConfiguration.languageRegionCode &&
            //         appConfiguration.root ===
            //             `/${appConfiguration.brand}/${appConfiguration.languageRegionCode}/`
            //     ).toString()
            // );
        } else if ('prod' !== process.env.REACT_APP_ACTIVE_PROFILE) {
            fmaTag.setAttribute(
                'data-ibm-url',
                AuthenticationService.getIbmUrl(brand)
            );
        }
        console.debug(
            'script-service.ts - ApplicationId is------',
            applicationId
        );
        if (applicationId) {
            fmaTag.setAttribute('data-app-id', applicationId);
            document.body.appendChild(fmaTag);
            console.debug('script-service.ts - FMA Loaded------');
        }
    }

    public loadDynatraceTags(removeFlag?: boolean) {
        const appConfiguration = new AppConfigurationService().getAppConfiguration();
        const dynaTraceTag = document.createElement('script');
        dynaTraceTag.setAttribute('id', 'dynatraceid');
        dynaTraceTag.setAttribute('type', 'text/javascript');
        dynaTraceTag.setAttribute('crossorigin', 'anonymous');
        if (appConfiguration.environment === 'prod') {
            dynaTraceTag.setAttribute(
                'src',
                'https://www.ford.com/9eebf7a8cc77ca3e_complete.js'
            );
        }

        if (appConfiguration.environment === 'uat') {
            dynaTraceTag.setAttribute(
                'src',
                'https://wwwqa.ford.com/f7eef2dcf02460cd_complete.js'
            );
        }
        if (consentService.nonEssentialScriptsAllowed() && !removeFlag) {
            dynaTraceTag.setAttribute('class', 'optanon-category-6');
            document.getElementsByTagName('body')[0].appendChild(dynaTraceTag);
        } else if (removeFlag) {
            const dynatraceRuxEle = document.getElementById('dtjsagent');
            const dynatraceEle = document.getElementById('dynatraceid');
            dynatraceRuxEle &&
                document
                    .getElementsByTagName('body')[0]
                    .removeChild(dynatraceRuxEle);
            dynatraceEle &&
                document
                    .getElementsByTagName('body')[0]
                    .removeChild(dynatraceEle);
        }
    }

    public loadOneTrustCookieBanner() {
        const appConfiguration = new AppConfigurationService().getAppConfiguration();
        const oneTrustTag = document.createElement('script');
        oneTrustTag.setAttribute(
            'src',
            'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
        );
        oneTrustTag.setAttribute('type', 'text/javascript');
        oneTrustTag.setAttribute('charset', 'UTF-8');
        if (appConfiguration.brand === 'lincoln') {
            oneTrustTag.setAttribute(
                'data-domain-script',
                'e68b490a-bbfa-4cf0-a492-de3c092da1df'
            );
        } else {
            oneTrustTag.setAttribute(
                'data-domain-script',
                '474f6f1e-8e77-40c9-97fd-ab58b6e08de0'
            );
        }
        document.getElementsByTagName('body')[0].appendChild(oneTrustTag);
    }

    public loadAnalyticsScript() {
        const appConfiguration = new AppConfigurationService().getAppConfiguration();
        if (
            consentService.nonEssentialScriptsAllowed() &&
            appConfiguration.analyticsEmbedScriptUrl
        ) {
            const analyticsTag = document.createElement('script');
            analyticsTag.src = appConfiguration.analyticsEmbedScriptUrl;
            analyticsTag.setAttribute('class', 'optanon-category-6');
            document.body.appendChild(analyticsTag);
        }
    }

    public loadCafexScript(removeFlag?: boolean) {
        let cafexScriptPath = cafexService.getCafexScriptPath();
        const brandUrls = ['/ford', '/lincoln', '/demo', '/lincolndemo'];
        for (let index = 0; index < brandUrls.length; index++) {
            const brandUrl = brandUrls[index];
            if (cafexScriptPath && location.pathname.startsWith(brandUrl)) {
                cafexScriptPath = location.origin + cafexScriptPath;
                break;
            }
        }
        const isWebview =
            typeof window !== undefined
                ? (window as any).SERVER_CONTEXT.webview === 'true'
                : false;
        if (cafexScriptPath && !isWebview) {
            const cafexTag = document.createElement('script');
            cafexTag.setAttribute('src', cafexScriptPath);
            cafexTag.setAttribute('id', 'cafexscriptid');
            if (consentService.nonEssentialScriptsAllowed() && !removeFlag) {
                cafexTag.setAttribute('class', 'optanon-category-6');
                document.body.appendChild(cafexTag);
            } else if (removeFlag) {
                const cafexEle = document.getElementById('cafexscriptid');
                cafexEle && document.body.removeChild(cafexEle);
            }
        }
    }

    public loadChatBot() {
        const {
            environment,
        } = new AppConfigurationService().getAppConfiguration();
        const env =
            environment === 'prod' || environment === 'uat'
                ? environment
                : 'qa';

        const scriptBlock = document.createElement('script');

        const url = `https://fordtccchat${env}sa.blob.core.windows.net/chatwidget/cxhubchat.js`;
        scriptBlock.setAttribute('src', url);
        scriptBlock.defer = true;
        document.head.appendChild(scriptBlock);
        const win: any = window;
        scriptBlock.addEventListener(
            'load',
            () =>
                win?.CXHubFord.Dynamics.Omnichannel.CXHubChatLogic.loadChatWidgetFromAzureBlob(
                    env
                ),
            true
        );
        scriptBlock.type = 'text/javascript';
    }

    public loadSophusScript() {
        const appConfiguration = new AppConfigurationService().getAppConfiguration();
        const countryCode = appConfiguration.countryCode;
        const isSophusScriptAvailable = Object.prototype.hasOwnProperty.call(
            SOPHUS_CHAT_COUNTRIES,
            countryCode
        );

        if (!isSophusScriptAvailable) {
            return;
        }

        const getCountryScript = () => {
            if (process.env.REACT_APP_ACTIVE_PROFILE === 'prod') {
                return SOPHUS_CHAT_COUNTRIES[countryCode];
            }
            return SOPHUS_CHAT_COUNTRIES.default;
        };

        const sophusTag = document.createElement('script');
        sophusTag.async = true;
        sophusTag.setAttribute('src', getCountryScript());
        document.body.appendChild(sophusTag);
    }

    public loadEvidonCookieBanner() {
        const appConfiguration = new AppConfigurationService().getAppConfiguration();
        if (appConfiguration.countryCode === 'ca') {
            const evidonTag = document.createElement('script');
            evidonTag.setAttribute('id', 'evidon-script');
            evidonTag.setAttribute('src', 'https://c.evidon.com/dg/dg.js');
            evidonTag.setAttribute('companyid', '1063');
            const evidonInnerScriptTag = document.createElement('script');
            evidonInnerScriptTag.setAttribute('id', 'evidon-inner-script');
            evidonInnerScriptTag.innerHTML =
                '!function(e){function n(e,n,o){var i=document,t="script",c=i.getElementsByTagName(t)[0];c||(c=i.head);var s=i.createElement(t);s.async=o,s.id=e,s.src=n,s.charset="utf-8",c.parentNode.insertBefore(s,c)}window.evidon={},window.evidon.id=e,window.evidon.test=!1;var o="//c.evidon.com/",i=function e(){var n=window.location.hostname.split(".");if(2===n.length)i=n[0];else if(n.length>2){var o,t=n[n.length-2];i=-1!==["co","com","info","web","info","gov","edu","biz","net","org"].indexOf(o=t)||-1!==["uk","us","fr","es","de","at","au","ae","be","br","ca","ch","cn","co","cz","dk","eg","eu","fi","gb","gr","hk","hr","hu","ie","in","jp","mx","nl","no","nz","pl","ro","ru","se"].indexOf(o)?n[n.length-3]:t}return i}(),t=o+"sitenotice/";n("evidon-notice",t+"evidon-sitenotice-tag.js",!1),n("evidon-location",o+"geo/country.js",!0),n("evidon-themes",t+e+"/snthemes.js",!0),i&&n("evidon-settings",t+e+"/"+i+(window.evidon.test?"/test":"")+"/settingsV2.js",!0),window.evidon.priorConsentCallback=function(e,n,o){},window.evidon.closeCallback=function(){},window.evidon.consentWithdrawnCallback=function(){},window.evidon.consentDeclinedCallback=function(){window.evidon.notice.dropSuppressionCookie(30)}}(1063);';
            document.body.appendChild(evidonTag);
            document.body.appendChild(evidonInnerScriptTag);
        }
    }
}

export const scriptService = new ScriptService();

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PreImagineEscapeHatch } from '../pre-imagine-components/pre-imagine-escape-hatch/pre-imagine-escape-hatch';
import { useSearchContent } from '../search-bar/hooks/use-search-content';
import { useHelpCardsContent } from '../../../views/page-not-found-view/hooks/use-help-cards-content';
import {
    FMButton,
    FMCard,
    FMCardFiftyFifty,
    FMCarousel,
    FMFullWidthBanner,
    FMVehicleSelector,
} from '@own/fds-react';
import './fm-vehicle-portal.scss';
import { PageType } from '../view-template/page-type';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import VehicleImageService from '../vehicle-portal/services/vehicle-image-service/vehicle-image-service';
import { useVehiclePageContent } from '../vehicle-portal/hooks/use-vehicle-page-content';
import { MouseflowService } from '../../../services/mouseflow-service/mouseflow-service';
import ServerSideService from '../../../services/server-side-service/server-side-service';
import useUserProfile from '../../../hooks/use-user-profile';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { NewAnalyticsService } from '../../../services/new-analytics-service/new-analytics-service';
import { useAnalytics } from '../../../hooks/use-analytics';
import { ShortcodeProviders } from '../../../services/shortcode-service/shortcode-service';
import { FMConnectedVehicleBar } from '../fm-connected-vehicle-bar/fm-connected-vehicle-bar';
import ConnectedVehicleService from '../../../services/connected-vehicle-service/connected-vehicle-service';
import {
    ConnectedVehicleResponse,
    Vehiclestatus,
} from '../../../models/connected-vehicle';
import {
    AuthenticationState,
    useAuthentication,
} from '../../../hooks/use-authentication';
import VehicleTypeService, {
    VehicleType,
} from '../../../services/vehicle-type-service/vehicle-type-service';
import ServerContext from '../../../contexts/serverContext';
import {
    BUTTONS,
    FMVehicleSelectorContent,
} from '@own/fds-react/dist/types/vehicleSelector.types';
import FMActionItems from '../fm-action-items/fm-action-items';
import { goToMyAccount } from '../../../views/vehicle-dashboard-view/vehicle-dashboard-view';
import ProfileService from '../../../services/profile-service/profile-service';
import { useHistory } from 'react-router-dom';
import { DisclaimerList } from '../../common/disclaimer-list/disclaimer-list';
import { useProcessedFmVehiclePortalContent } from './use-processed-fm-vehicle-portal-content';
import { DisclaimerContent } from '../../../services/disclaimers-service/disclaimers-service';
import { useFMHubComponent } from './fm-hub';
import { Breadcrumbs } from '../breadcrumbs/breadcrumbs';
import { useVehicleDashboardContent } from '../vehicle-portal/hooks/use-vehicle-dashboard-content';
import FMPreferredDealer from '../../common/fm-preferred-dealer/fm-preferred-dealer';
import { findByAlias } from '../../../routes';
import { transformRelativeUrlValidateRoot } from '../../utils/router-util/router-util';
import useFooterDisplay from '../../../hooks/use-footer-display';

interface Props {
    page: PageType;
    vehicleAttributes: VehicleAttributes;
    onChange?: any;
}

const VEHICLE_BUTTONS: BUTTONS = {
    ADD: 'Add Vehicle',
    CHANGE: 'Change Vehicle',
};

const FMVehiclePortal = (props: Props) => {
    const profileService = new ProfileService();
    const history = useHistory();
    const [authenticationState, login] = useAuthentication();
    const vehiclePageContent = useVehiclePageContent();
    const vehicleName = `${props.vehicleAttributes?.year ?? ''} ${
        props.vehicleAttributes.model
    }`;
    const PAGE_DASHBOARD = 'DASHBOARD';
    const isDashboardPage = props.page === PAGE_DASHBOARD;
    const vehicleDashboardContent = useVehicleDashboardContent();
    const breadcrumb = isDashboardPage
        ? vehicleDashboardContent?.breadcrumb
        : vehicleName;
    const searchContent = useSearchContent();
    const helpCardsContent = useHelpCardsContent();
    const [vehicleType, setVehicleType] = useState<VehicleType>();
    const [vehicleImageUrl, setVehicleImageUrl] = useState('');
    const [vehicleStatus, setVehicleStatus] = useState<
        ConnectedVehicleResponse | undefined
    >();
    const [connectedVehicleInfo, setConnectedVehicleInfo] = useState<
        Vehiclestatus | undefined
    >();
    const [currentVehicle, setCurrentVehicle] = useState<VehicleAttributes>(
        props.vehicleAttributes
    );

    let disclaimers: DisclaimerContent[] = [];
    const { fmHubsTabs, fmHubsDisclaimers } = useFMHubComponent(
        props.vehicleAttributes
    );
    disclaimers = disclaimers.concat(fmHubsDisclaimers);
    const {
        processedFMCardFiftyFiftyLeft,
        processedFMCardFiftyFiftyRight,
        processedFMFullWidthBanner,
        processedFMCarousel,
        processedDisclaimers,
    } = useProcessedFmVehiclePortalContent(disclaimers, props);
    disclaimers = processedDisclaimers;
    const profile = useUserProfile();
    useFooterDisplay([profile]);
    const {
        currentLanguageRegionCode,
        current3LetterCountryCode,
        root,
    } = useContext(ServerContext);
    const { fmaRegion } = new AppConfigurationService().getAppConfiguration();

    const [fireEvents] = useAnalytics();
    const handleRelativeCta = (ctaPath: string | undefined) => {
        return transformRelativeUrlValidateRoot(ctaPath || '', root);
    };
    const fmBannerCtaAnalytic = (ctaPath: string | undefined) => {
        return ctaPath
            ?.split('/')
            .filter(slug => slug && slug !== '')
            ?.pop()
            ?.replaceAll('-', ' ');
    };
    const fmCardTitleConvert = (title: string) => {
        return title
            .toLowerCase()
            .replace(/[^a-zA-Z0-9 ]/g, ' ')
            .trim();
    };
    const VehicleImageSection = () => {
        return (
            <>
                {vehicleImageUrl &&
                    vehiclePageContent &&
                    !vehiclePageContent.hideVehicleImage && (
                        <img
                            className="fm-vehicle-image"
                            src={vehicleImageUrl}
                            alt={vehicleName}
                            crossOrigin="anonymous"
                            width="616"
                            height="346"
                            fetchpriority={'high'}
                        />
                    )}
            </>
        );
    };
    const handleClickEvent = async (
        ctaPath = '' as string,
        eventName: string,
        shortCodeProvider = {} as ShortcodeProviders
    ) => {
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            ctaPath
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else if (eventName) {
            fireEvents(eventName, undefined, shortCodeProvider);
        }
    };

    const goToDashboard = useCallback(
        (index: number) => {
            history.push(findByAlias('FMVehicleDashboardView'), {
                selectedIndex: index,
            });
        },
        [history]
    );

    const clientSideSetItem = () => {
        if (!ServerSideService.isClientSide()) return;

        const apaCheck = profile && fmaRegion === 'apa';
        if (apaCheck) {
            sessionStorage.setItem('email', profile.profile.email);
        }

        if (props.vehicleAttributes.vin) {
            sessionStorage.setItem('selectedVin', props.vehicleAttributes.vin);
            localStorage.setItem('USER_VIN', props.vehicleAttributes.vin);
        } else {
            sessionStorage.removeItem('selectedVin');
        }
    };
    clientSideSetItem();

    useEffect(() => {
        if (profile) {
            if (profile.vehicles.length > 0) {
                const vehicles = [...profile.vehicles]
                    .sort(
                        (vehicle1, vehicle2) =>
                            parseInt(vehicle2.modelYear, 10) -
                            parseInt(vehicle1.modelYear, 10)
                    )
                    .map(vehicleDetail => {
                        return {
                            year: parseInt(vehicleDetail.modelYear, 10),
                            make: vehicleDetail.make,
                            model: vehicleDetail.modelName,
                            vin: vehicleDetail.vin,
                            ownerState: vehicleDetail.ownerState,
                            registrationNo: vehicleDetail.licenseplate,
                            fuelType: vehicleDetail.fuelType,
                            engineType: vehicleDetail.engineType,
                            warrantyStartDate: vehicleDetail.warrantyStartDate,
                        };
                    });
                setVehiclesData(vehicles);
            }
        }
    }, [profile]);

    useEffect(() => {
        setVehicleImageUrl('');
        if (props.vehicleAttributes.vin) {
            new VehicleImageService()
                .getVehicleImage(props.vehicleAttributes, props.page)
                .then((response: any) => {
                    setVehicleImageUrl(response);
                });
        }
    }, [props.vehicleAttributes.vin]);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    useEffect(() => {
        MouseflowService.setVariable(
            'vehicleYMM',
            `${props.vehicleAttributes.year}·${props.vehicleAttributes.make}·${props.vehicleAttributes.model}`
        );
    }, [
        [
            props.vehicleAttributes.year,
            props.vehicleAttributes.make,
            props.vehicleAttributes.model,
            props.vehicleAttributes.vin,
        ].join(' '),
    ]);

    useEffect(() => {
        setConnectedVehicleInfo(undefined);
        if (
            !vehicleStatus &&
            props.vehicleAttributes.vin &&
            authenticationState == AuthenticationState.Authenticated
        )
            new ConnectedVehicleService()
                .getVehicleStatus(
                    props.vehicleAttributes.vin,
                    props.vehicleAttributes?.year
                )
                .then(vehicleStatusData => {
                    setVehicleStatus(vehicleStatusData);
                });
        if (
            authenticationState === AuthenticationState.Authenticated &&
            isDashboardPage &&
            props.vehicleAttributes.vin
        ) {
            new ConnectedVehicleService()
                .getVehicleStatus(
                    props.vehicleAttributes?.vin || '',
                    props.vehicleAttributes?.year
                )
                .then(response => {
                    if (response?.vehiclestatus) {
                        setConnectedVehicleInfo(response.vehiclestatus);
                    } else {
                        setConnectedVehicleInfo(undefined);
                    }
                })
                .catch(() => {
                    setConnectedVehicleInfo(undefined);
                });
        }
        return () => {
            setConnectedVehicleInfo(undefined);
        };
    }, [props?.vehicleAttributes?.vin, authenticationState]);

    useEffect(() => {
        if (
            authenticationState === AuthenticationState.Authenticated &&
            isDashboardPage &&
            props?.vehicleAttributes?.vin
        ) {
            new VehicleTypeService()
                .request(
                    props.vehicleAttributes.vin,
                    currentLanguageRegionCode,
                    current3LetterCountryCode?.toUpperCase()
                )
                .then(typeResponse => {
                    typeResponse && setVehicleType(typeResponse);
                });
        }
    }, [props.vehicleAttributes?.vin, authenticationState]);
    const [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]);
    const fmVehicleSelectorContent: FMVehicleSelectorContent = {
        addVehicleCtaLabel:
            vehiclePageContent?.addVehicleCtaLabel || 'Add Vehicle',
        changeVehicleButtonLabel:
            vehiclePageContent?.changeVehicleButtonLabel || 'Change Vehicle',
        vinLabel: vehiclePageContent?.vinLabel || 'VIN:',
        switchVehicle:
            vehiclePageContent?.switchVehicleButtonLabel || 'Switch Vehicle',
    };
    const storeUserPreference = (vehicle: VehicleAttributes) => {
        profile &&
            profile.vehicles.length > 0 &&
            profileService.persistVehicleSelection(
                profile.profile.email,
                vehicle.vin
            );
    };

    const getVehicleFromUserPreferences = () => {
        if (!profile || vehiclesData.length <= 0) return;
        let vehicle: VehicleAttributes = vehiclesData[0];
        const selectedVin = profileService.getUserPreferences(
            profile.profile.email
        )?.lastSelectedVin;
        if (selectedVin) {
            vehicle =
                vehiclesData.find(vehicle => vehicle.vin === selectedVin) ||
                vehiclesData[0];
        }
        setCurrentVehicle(vehicle);
    };

    useEffect(() => {
        getVehicleFromUserPreferences();
    }, [vehiclesData]);

    const selectVehicle = (
        label: string,
        index: number,
        value: string | undefined
    ) => {
        if (value === VEHICLE_BUTTONS.CHANGE) {
            history.push('/');
        } else if (value === VEHICLE_BUTTONS.ADD) {
            fireEvents(
                'add-vehicle-onclick-event',
                undefined,
                undefined,
                false
            );
            goToMyAccount(vehiclePageContent);
        } else {
            if (props.onChange) {
                props.onChange(label, index);
            }
            fireEvents(
                'change-vehicle-onclick-event',
                undefined,
                undefined,
                false
            );
            if (authenticationState === AuthenticationState.Authenticated) {
                storeUserPreference(vehiclesData[index]);
                if (props.page !== PAGE_DASHBOARD) goToDashboard(index);
            }
        }
    };

    return (
        <div className={'fm-vehicle-portal-wrapper'}>
            <div className={'gradient-container'}>
                <section className={'fm-vehicle-head'}>
                    {breadcrumb && (
                        <Breadcrumbs
                            currentPage={breadcrumb}
                            type="common"
                            crumbSeparator={'/'}
                        />
                    )}
                    <FMVehicleSelector
                        value={currentVehicle}
                        content={fmVehicleSelectorContent}
                        vehiclesData={vehiclesData}
                        handleMenuItemClick={selectVehicle}
                    />
                    <div className={'vehicle-title-image'}>
                        <div className={'vehicle-name'}>
                            {props.vehicleAttributes?.year}{' '}
                            {props.vehicleAttributes?.model}
                            {props.vehicleAttributes?.vin && (
                                <div className={'vehicle-vin'}>
                                    {vehiclePageContent?.vinLabel}{' '}
                                    {props.vehicleAttributes?.vin}
                                </div>
                            )}
                            <FMPreferredDealer
                                profile={profile}
                                vehicleAttributes={props?.vehicleAttributes}
                                root={root}
                                fmaRegion={fmaRegion}
                            />
                        </div>
                        <VehicleImageSection />
                    </div>
                </section>
                {authenticationState == AuthenticationState.Authenticated &&
                    connectedVehicleInfo && (
                        <FMConnectedVehicleBar
                            vehicleAttributes={props?.vehicleAttributes}
                            connectedVehicleInfo={connectedVehicleInfo}
                            isTpmAvailable={
                                vehicleType?.isTpmAvailable || false
                            }
                        />
                    )}
                <FMActionItems
                    vehicleAttributes={props?.vehicleAttributes}
                    authenticationState={authenticationState}
                    login={login}
                    region={fmaRegion}
                />
            </div>
            {fmHubsTabs && <div className={'hub'}>{fmHubsTabs}</div>}
            {processedFMCardFiftyFiftyLeft &&
                processedFMCardFiftyFiftyLeft?.hide === false && (
                    <FMCardFiftyFifty
                        {...processedFMCardFiftyFiftyLeft}
                        {...{
                            headline: processedFMCardFiftyFiftyLeft?.title,
                            title: '',
                            description:
                                processedFMCardFiftyFiftyLeft?.description,
                            mediaSide: 'right',
                            media: {
                                src: `${process.env.REACT_APP_AEM_BASE_URL}${processedFMCardFiftyFiftyLeft?.imagePath}`,
                                alt:
                                    processedFMCardFiftyFiftyLeft?.imageAltText ||
                                    '',
                            },
                            button: {
                                type: 'primary',
                                label: processedFMCardFiftyFiftyLeft?.cta1Label,
                                disabled: false,
                                ariaLabel:
                                    processedFMCardFiftyFiftyLeft?.cta1AriaLabel,
                                href: handleRelativeCta(
                                    processedFMCardFiftyFiftyLeft.cta1Path
                                ),
                                onClick: () =>
                                    handleClickEvent(
                                        processedFMCardFiftyFiftyLeft?.cta1Path,
                                        'fm-5050-content-left-click-event'
                                    ),
                                target: processedFMCardFiftyFiftyLeft.openCTA1InNewTab
                                    ? '_blank'
                                    : '_self',
                            },
                            secondaryButton: {
                                type: 'secondary',
                                label: processedFMCardFiftyFiftyLeft.cta2Label,
                                ariaLabel:
                                    processedFMCardFiftyFiftyLeft.cta2AriaLabel,
                                href: handleRelativeCta(
                                    processedFMCardFiftyFiftyLeft.cta2Path
                                ),
                                disabled: false,
                                onClick: () =>
                                    handleClickEvent(
                                        processedFMCardFiftyFiftyLeft?.cta2Path,
                                        'fm-5050-content-left-click-event'
                                    ),
                                target: processedFMCardFiftyFiftyLeft.openCTA2InNewTab
                                    ? '_blank'
                                    : '',
                            },
                        }}
                    />
                )}
            {processedFMFullWidthBanner && !processedFMFullWidthBanner?.hide && (
                <FMFullWidthBanner
                    title={processedFMFullWidthBanner?.title}
                    subCopy={processedFMFullWidthBanner?.description}
                    imgPath={
                        process.env.REACT_APP_AEM_BASE_URL +
                        processedFMFullWidthBanner.imagePath
                    }
                    imgAlt={processedFMFullWidthBanner.imageAltText}
                    button={{
                        type: 'tertiary',
                        label: processedFMFullWidthBanner.cta1Label,
                        ariaLabel: processedFMFullWidthBanner.cta1AriaLabel,
                        href: handleRelativeCta(
                            processedFMFullWidthBanner.cta1Path
                        ),
                        chevron: 'right',
                        disabled: false,
                        onClick: () =>
                            handleClickEvent(
                                processedFMFullWidthBanner.cta1Path,
                                'fm-full-width-banner-click-event',
                                {
                                    ctaType: fmBannerCtaAnalytic(
                                        processedFMFullWidthBanner.cta1Path
                                    ),
                                }
                            ),
                        target: processedFMFullWidthBanner.openCTA1InNewTab
                            ? '_blank'
                            : '',
                    }}
                />
            )}
            {processedFMCardFiftyFiftyRight &&
                processedFMCardFiftyFiftyRight?.hide === false && (
                    <FMCardFiftyFifty
                        {...processedFMCardFiftyFiftyRight}
                        {...{
                            headline: processedFMCardFiftyFiftyRight?.title,
                            title: '',
                            description:
                                processedFMCardFiftyFiftyRight?.description,
                            mediaSide: 'left',
                            media: {
                                src: `${process.env.REACT_APP_AEM_BASE_URL}${processedFMCardFiftyFiftyRight?.imagePath}`,
                                alt:
                                    processedFMCardFiftyFiftyRight?.imageAltText ||
                                    '',
                            },
                            button: {
                                type: 'primary',
                                label:
                                    processedFMCardFiftyFiftyRight?.cta1Label,
                                disabled: false,
                                ariaLabel:
                                    processedFMCardFiftyFiftyRight?.cta1AriaLabel,
                                href: handleRelativeCta(
                                    processedFMCardFiftyFiftyRight?.cta1Path
                                ),
                                onClick: () =>
                                    handleClickEvent(
                                        processedFMCardFiftyFiftyRight?.cta1Path,
                                        'fm-5050-content-right-click-event'
                                    ),
                                target: processedFMCardFiftyFiftyRight.openCTA1InNewTab
                                    ? '_blank'
                                    : '',
                            },
                            secondaryButton: {
                                type: 'secondary',
                                label: processedFMCardFiftyFiftyRight.cta2Label,
                                ariaLabel:
                                    processedFMCardFiftyFiftyRight.cta2AriaLabel,
                                href: handleRelativeCta(
                                    processedFMCardFiftyFiftyRight.cta2Path
                                ),
                                disabled: false,
                                onClick: () =>
                                    handleClickEvent(
                                        processedFMCardFiftyFiftyRight?.cta2Path,
                                        'fm-5050-content-right-click-event'
                                    ),
                                target: processedFMCardFiftyFiftyRight.openCTA2InNewTab
                                    ? '_blank'
                                    : '',
                            },
                        }}
                    />
                )}
            {processedFMCarousel &&
                !processedFMCarousel?.hide &&
                processedFMCarousel.fmCards.length > 0 && (
                    <>
                        <div className={'fm-carousel-header'}>
                            {processedFMCarousel.title && (
                                <h2 className={'fm-carousel-title'}>
                                    {processedFMCarousel?.title}
                                </h2>
                            )}
                            {processedFMCarousel?.ctaLabel && (
                                <FMButton
                                    type={'secondary'}
                                    label={processedFMCarousel?.ctaLabel}
                                    ariaLabel={
                                        processedFMCarousel?.ctaAriaLabel
                                    }
                                    chevron={'right'}
                                    href={handleRelativeCta(
                                        processedFMCarousel?.ctaPath
                                    )}
                                    onClick={() =>
                                        handleClickEvent(
                                            processedFMCarousel?.ctaPath,
                                            'fm-carousel-click-event'
                                        )
                                    }
                                />
                            )}
                        </div>
                        <FMCarousel
                            rightButtonAriaLabel={
                                processedFMCarousel?.rightButtonAriaLabel
                            }
                            leftButtonAriaLabel={
                                processedFMCarousel?.leftButtonAriaLabel
                            }
                            items={processedFMCarousel?.fmCards}
                            render={(item: any) => (
                                <FMCard
                                    {...item}
                                    borderless={true}
                                    imageSrc={`${process.env.REACT_APP_AEM_BASE_URL}${item.imagePath}`}
                                    imageAlt={item.imageAltText}
                                    ctaLabel={item.cta1Label}
                                    ctaHref={handleRelativeCta(item.cta1Path)}
                                    ctaType={'primary'}
                                    ctaTheme={'light'}
                                    ctaOnClick={() => {
                                        handleClickEvent(
                                            item.ctaHref,
                                            'fm-vehicle-dashboard-carousel-cta',
                                            {
                                                smashHomeClick: {
                                                    cardName: fmCardTitleConvert(
                                                        item.title
                                                    ),
                                                },
                                            }
                                        );
                                    }}
                                    cta2Label={item.cta2Label}
                                    cta2Href={handleRelativeCta(item.cta2Path)}
                                    cta2Type={'secondary'}
                                    cta2Theme={'light'}
                                    cta2OnClick={() => {
                                        handleClickEvent(
                                            item.cta2Href,
                                            'fm-vehicle-dashboard-carousel-cta',
                                            {
                                                smashHomeClick: {
                                                    cardName: fmCardTitleConvert(
                                                        item.title
                                                    ),
                                                },
                                            }
                                        );
                                    }}
                                >
                                    {item?.children}
                                </FMCard>
                            )}
                        />
                    </>
                )}
            {disclaimers.length > 0 && (
                <DisclaimerList disclaimers={disclaimers} />
            )}
            {searchContent && helpCardsContent && (
                <PreImagineEscapeHatch
                    searchContent={searchContent}
                    helpCardsContent={helpCardsContent}
                />
            )}
        </div>
    );
};

export default FMVehiclePortal;

import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import { useAnalytics } from '../../hooks/use-analytics';
import ServerContext from '../../contexts/serverContext';
import { ASTUTE } from '../../support/support-constants';
import { DisclaimerContent } from '../../services/disclaimers-service/disclaimers-service';
import { DisclaimerList } from '../../components/common/disclaimer-list/disclaimer-list';
import { useProcessedHomeContent } from '../home-page/hooks/use-processed-home-content';
import '../home-page/home-page.scss';
import { useSearchContent } from '../../components/sections/search-bar/hooks/use-search-content';
import { useHelpCardsContent } from '../page-not-found-view/hooks/use-help-cards-content';
import DisclosuresLinkSyndicationAware from '../../components/sections/disclosures-link/disclosures-link-syndication-aware';
import useSeoConfig from '../../hooks/use-seo-config';
import {
    AuthenticationState,
    useAuthentication,
} from '../../hooks/use-authentication';
import { StringUtil } from '../../components/utils/string-util/string-util';
import { USER_GREETING_DELAY_SECONDS } from '../../constants';
import SkinnyBanner from '../../components/common/skinny-banner/skinny-banner';
import { useSkinnyBannerContent } from '../../components/common/skinny-banner/hook/use-skinny-banner';
import { MastheadPreImagine } from '../../components/common/masthead-preimagine/masthead-preimagine';
import { FMButton, FMCard, FMCarousel, FMCardFiftyFifty } from '@own/fds-react';
import { FMCardProps } from '@own/fds-react/dist/types/fm-card.types';
import { useExperienceContent } from '../../hooks/use-server-content';
import { HeaderFragment } from '../../models/experiencefragments/header';
import AuthenticationService from '../../services/authentication-service/authentication-service';
import { VehicleSelectorWrapper } from '../home-page/components/vehicle-selector-wrapper/vehicle-selector-wrapper';
import { InformationGrid } from '../../components/sections/information-grid/information-grid';
import { PreImagineEscapeHatch } from '../../components/sections/pre-imagine-components/pre-imagine-escape-hatch/pre-imagine-escape-hatch';
import { PreImagineFindASolution } from '../../components/sections/pre-imagine-find-a-solution/pre-imagine-find-a-solution';
import { FindASolutionFragment } from '../../models/experiencefragments/find-a-solution';
import { TabsFragment } from '../../models/experiencefragments/tabs';
import PreImagineBrowseByTopic from '../../support/components/pre-imagine-browse-by-topic/pre-imagine-browse-by-topic';
import { NewAnalyticsService } from '../../services/new-analytics-service/new-analytics-service';
import { useHistory } from 'react-router-dom';
import { URLUtil } from '../../components/utils/url-util/url-util';
import CategoryViewUtil from '../category-view/category-view-util';
import './home-preimagine.scss';
import useFooterDisplay from '../../hooks/use-footer-display';

export const HomePagePreImagine = () => {
    const [showPersonalGreeting, setShowPersonalGreeting] = useState(false);
    const [email, setEmail] = useState<string | null>();
    const [fname, setFname] = useState<string | null>();
    const [authenticationState] = useAuthentication();
    const [fireEvents] = useAnalytics();
    const history = useHistory();
    const authService = new AuthenticationService();
    let disclaimers: DisclaimerContent[] = [];
    useAnalytics(
        ['target-trigger-view', 'smash-owner-home'],
        'target-owner-home'
    );
    const {
        processedDisclaimers,
        processedHomeContent,
        processedFeaturedArticlesCarouselContent,
        processedBbtContent,
        processedSmartTilesContent,
        processedArticlesTrio1,
        processedArticlesTrio2,
        processedComponentBox5050,
        processedSmashCard,
    } = useProcessedHomeContent(disclaimers);

    const skinnyBannerContent = useSkinnyBannerContent(
        'landing',
        'skinny-banner',
        false
    );
    disclaimers = disclaimers.concat(processedDisclaimers);
    const page = 'Landing PreImagine';
    const { currentLanguageRegionCode } = useContext(ServerContext);
    useSeoConfig('home');

    const searchContent = useSearchContent();
    const helpCardsContent = useHelpCardsContent();

    const showGreeting = (delay: string) => {
        const differenceInSeconds =
            (new Date().getTime() - Number.parseInt(delay)) / 1000;
        return differenceInSeconds > USER_GREETING_DELAY_SECONDS;
    };
    const category = 'landing';
    const [findASolutionContent] = useExperienceContent<FindASolutionFragment>(
        category + '/tab-components',
        'find-a-solution',
        'findasolution',
        undefined,
        false
    );
    const canShowGreeting = () => {
        let show = false;
        const ownLastLoggedInUser = localStorage.getItem('ownLastLoggedInUser');
        const ownUserFirstTimeLogin = localStorage.getItem(
            'ownUserFirstTimeLogin'
        );
        const userGreetingLastActionedAt = localStorage.getItem(
            'userGreetingLastActionedAt'
        );
        if (ownUserFirstTimeLogin) {
            show = showGreeting(ownUserFirstTimeLogin);
        } else if (ownLastLoggedInUser && userGreetingLastActionedAt) {
            show = showGreeting(userGreetingLastActionedAt);
        } else show = false;
        return show;
    };

    useEffect(() => {
        if (
            canShowGreeting() &&
            authenticationState !== AuthenticationState.Authenticated
        ) {
            const ownLastLoggedInUser = localStorage.getItem(
                'ownLastLoggedInUser'
            );
            const ownLastLoggedInUserFname = localStorage.getItem(
                'ownLastLoggedInUserFname'
            );
            if (
                ownLastLoggedInUser &&
                ownLastLoggedInUserFname &&
                processedHomeContent?.userGreetingLine1 &&
                processedHomeContent?.userGreetingLine2 &&
                processedHomeContent?.userGreetingLine3
            ) {
                setEmail(ownLastLoggedInUser);
                setFname(ownLastLoggedInUserFname);
                setShowPersonalGreeting(true);
            }
        }
    }, [authenticationState]);

    const onGreetingAction = (action: 'Login' | 'Dismiss') => {
        if (showPersonalGreeting) {
            if (action === 'Dismiss') setShowPersonalGreeting(false);
            const nowEpoch = new Date().getTime();
            localStorage.setItem(
                'userGreetingLastActionedAt',
                nowEpoch.toString()
            );
            localStorage.removeItem('ownUserFirstTimeLogin');
        }
    };

    const maskedEmail = (email: string) => {
        const emailPrefix = email.split('@')[0];
        const emailSuffix = email.split('@')[1];
        const masked =
            emailPrefix.substring(0, 2) +
            emailPrefix.substring(2, emailPrefix.length).replace(/./g, '*');
        return masked + '@' + emailSuffix;
    };

    const personalizedHeadline = () => {
        if (email && fname && processedHomeContent?.userGreetingLine1) {
            return processedHomeContent.userGreetingLine1.replace(
                '[fname]',
                StringUtil.capitalizeFirstLetter(fname)
            );
        } else return processedHomeContent?.bannerTitle;
    };

    const personalizedDescription = () => {
        if (email && processedHomeContent?.userGreetingLine2)
            return processedHomeContent.userGreetingLine2.replace(
                '[email]',
                maskedEmail(email)
            );
        else return processedHomeContent?.bannerDescription;
    };

    const [content] = useExperienceContent<HeaderFragment>(
        'common',
        'header-footer',
        'header'
    );
    const login = () => {
        authService.login();
    };
    const [tabsContent] = useExperienceContent<TabsFragment>(
        category,
        'tabs',
        'tabs',
        undefined,
        false
    );

    const handleFMCardFiftyFifty = async (
        ctaPath: string,
        eventName: string
    ) => {
        const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
            ctaPath
        );
        if (referralExit) {
            NewAnalyticsService.fireReferralExitsEvent(
                fireEvents,
                referralExit
            );
        } else if (eventName) {
            fireEvents(eventName, undefined, {});
        }
    };

    function handleFMCardClick(item: FMCardProps, eventName: string) {
        return async () => {
            const referralExit = await new NewAnalyticsService().findMatchingReferralExit(
                item.ctaHref || ''
            );
            eventName &&
                !referralExit &&
                fireEvents(
                    eventName,
                    undefined,
                    {
                        smashHomeClick: {
                            cardName: item.ctaLabel,
                        },
                    },
                    false
                );
            NewAnalyticsService.fireReferralExitEventBasedOnUrl(
                item.ctaHref || '',
                fireEvents
            );
            if (item.ctaHref && URLUtil.isRelativeUrl(item.ctaHref)) {
                history.push(item.ctaHref || '');
            }
        };
    }
    useFooterDisplay([]);

    return (
        <>
            {processedHomeContent ? (
                <div className="shared-view smash-home">
                    <MastheadPreImagine
                        header={processedHomeContent?.mastheadTitle}
                        headline={
                            showPersonalGreeting
                                ? personalizedHeadline()
                                : processedHomeContent.bannerTitle
                        }
                        description={
                            showPersonalGreeting
                                ? personalizedDescription()
                                : processedHomeContent.bannerDescription
                        }
                        mobileImg={
                            processedHomeContent.homeBackgroundImageMobile || ''
                        }
                        desktopImg={
                            processedHomeContent.homeBackgroundImageDesktop ||
                            ''
                        }
                    >
                        {content &&
                            content['main-nav-right'].signIn.signInCtaTitle &&
                            authenticationState ===
                                AuthenticationState.Unauthenticated && (
                                <FMButton
                                    type={'primary'}
                                    label={
                                        content['main-nav-right'].signIn
                                            .signInCtaTitle
                                    }
                                    onClick={() => {
                                        fireEvents(
                                            'register-login-onclick-event',
                                            undefined,
                                            undefined,
                                            false
                                        );
                                        login();
                                        onGreetingAction('Dismiss');
                                    }}
                                />
                            )}
                        {showPersonalGreeting &&
                            fname &&
                            processedHomeContent.userGreetingLine3 && (
                                <FMButton
                                    onClick={() => onGreetingAction('Dismiss')}
                                    type={'secondary'}
                                    label={processedHomeContent.userGreetingLine3.replace(
                                        '[fname]',
                                        fname
                                    )}
                                />
                            )}
                    </MastheadPreImagine>
                    {tabsContent &&
                        findASolutionContent &&
                        !findASolutionContent.hide && (
                            <PreImagineFindASolution
                                content={findASolutionContent}
                                analyticsEventName="smash-home-articles-onclick-event"
                                title={tabsContent?.tabs?.map((tab, index) => {
                                    if (index == 1) {
                                        return tab.title;
                                    }
                                })}
                            ></PreImagineFindASolution>
                        )}
                    {tabsContent && (
                        <VehicleSelectorWrapper
                            title={tabsContent?.tabs?.map((tab, index) => {
                                if (index == 0) {
                                    return tab.title;
                                }
                            })}
                            page={page}
                        />
                    )}
                    {skinnyBannerContent && (
                        <SkinnyBanner {...skinnyBannerContent} />
                    )}
                    {processedFeaturedArticlesCarouselContent &&
                        !processedFeaturedArticlesCarouselContent.hide && (
                            <>
                                <div className="fm-home-featured-articles-carousel-header">
                                    {processedFeaturedArticlesCarouselContent.title && (
                                        <h2 className="fm-home-featured-articles-carousel__title">
                                            {
                                                processedFeaturedArticlesCarouselContent.title
                                            }
                                        </h2>
                                    )}
                                    {processedFeaturedArticlesCarouselContent.subtitle && (
                                        <div className="fm-home-featured-articles-carousel__subtitle">
                                            {
                                                processedFeaturedArticlesCarouselContent.subtitle
                                            }
                                        </div>
                                    )}
                                </div>
                                <FMCarousel
                                    instanceName="home-article-carousel-1"
                                    isUniMode={
                                        processedFeaturedArticlesCarouselContent
                                            ?.cards?.length < 4
                                    }
                                    items={
                                        processedFeaturedArticlesCarouselContent.cards
                                    }
                                    render={(item: FMCardProps) => (
                                        <FMCard
                                            {...item}
                                            imageSrc={`${process.env.REACT_APP_AEM_BASE_URL}${item.imageSrc}`}
                                            ctaOnClick={handleFMCardClick(
                                                item,
                                                'smash-home-owning-ford-onclick-event'
                                            )}
                                            cta2OnClick={handleFMCardClick(
                                                item,
                                                ''
                                            )}
                                        >
                                            {item.children ?? ''}
                                        </FMCard>
                                    )}
                                    {...{
                                        ...processedFeaturedArticlesCarouselContent,
                                        title: '',
                                        subtitle: '',
                                    }}
                                />
                            </>
                        )}
                    {ASTUTE.SUPPORTED_MARKETS.includes(
                        currentLanguageRegionCode
                    ) && (
                        <PreImagineBrowseByTopic
                            bbtContent={processedBbtContent}
                            analyticsClickEventName="smash-home-bbt-category-onclick-event"
                        />
                    )}
                    {processedSmashCard && (
                        <FMCardFiftyFifty
                            {...{
                                ...processedSmashCard,
                                mediaSide: 'left',
                                media: {
                                    src:
                                        process.env.REACT_APP_AEM_BASE_URL +
                                        processedSmashCard.desktopImagePath,
                                    alt: processedSmashCard.imageAltText,
                                },
                                button: {
                                    type: 'primary',
                                    label: processedSmashCard.ctaLabel,
                                    href: processedSmashCard.ctaPath,
                                    disabled: false,
                                    onClick: () =>
                                        handleFMCardFiftyFifty(
                                            processedSmashCard.ctaPath,
                                            'smash-5050-click-event'
                                        ),
                                    target: processedSmashCard.targetBlank
                                        ? '_blank'
                                        : '',
                                },
                                secondaryButton: {
                                    type: 'secondary',
                                    label: processedSmashCard.cta2Label,
                                    href: processedSmashCard.cta2Path,
                                    disabled: false,
                                    onClick: () =>
                                        handleFMCardFiftyFifty(
                                            processedSmashCard?.cta2Path,
                                            ''
                                        ),
                                    target: processedSmashCard.target2Blank
                                        ? '_blank'
                                        : '',
                                },
                            }}
                        />
                    )}
                    {processedSmartTilesContent && (
                        <InformationGrid
                            smartTileContent={processedSmartTilesContent}
                            analyticsClickEventName={'tiles-onclick-event'}
                        />
                    )}
                    {processedArticlesTrio1 && (
                        <>
                            <div className="fm-home-articles-carousel-header">
                                {processedArticlesTrio1.sectionHeading && (
                                    <h2 className="fm-home-articles-carousel__title">
                                        {processedArticlesTrio1.sectionHeading}
                                    </h2>
                                )}
                                {processedArticlesTrio1.sectionSubCopy && (
                                    <div className="fm-home-articles-carousel__subtitle">
                                        {processedArticlesTrio1.sectionSubCopy}
                                    </div>
                                )}
                            </div>
                            <FMCarousel
                                instanceName="home-article-trio-carousel"
                                isUniMode={
                                    processedArticlesTrio1?.articles?.length < 4
                                }
                                render={(item: FMCardProps) => (
                                    <FMCard
                                        {...item}
                                        imageSrc={`${item.imageSrc}`}
                                    >
                                        {item.children ?? ''}
                                    </FMCard>
                                )}
                                items={CategoryViewUtil.MapArticlesToFMCardProps(
                                    processedArticlesTrio1?.articles ?? []
                                )}
                            ></FMCarousel>
                        </>
                    )}

                    {processedComponentBox5050 && (
                        <FMCardFiftyFifty
                            {...{
                                ...processedComponentBox5050,
                                mediaSide: 'left',
                                media: {
                                    src:
                                        process.env.REACT_APP_AEM_BASE_URL +
                                        processedComponentBox5050.desktopImagePath,
                                    alt: processedComponentBox5050.imageAltText,
                                },
                                button: {
                                    type: 'primary',
                                    label: processedComponentBox5050.ctaLabel,
                                    href: processedComponentBox5050.ctaPath,
                                    disabled: false,
                                    onClick: () =>
                                        handleFMCardFiftyFifty(
                                            processedComponentBox5050?.ctaPath,
                                            ''
                                        ),
                                },
                                secondaryButton: {
                                    type: 'secondary',
                                    label: processedComponentBox5050.cta2Label,
                                    href: processedComponentBox5050.cta2Path,
                                    disabled: false,
                                    onClick: () =>
                                        handleFMCardFiftyFifty(
                                            processedComponentBox5050?.cta2Path,
                                            ''
                                        ),
                                },
                            }}
                        />
                    )}
                    {processedArticlesTrio2 && (
                        <>
                            <div className="fm-home-articles-carousel-header">
                                {processedArticlesTrio2.sectionHeading && (
                                    <h2 className="fm-home-articles-carousel__title">
                                        {processedArticlesTrio2.sectionHeading}
                                    </h2>
                                )}
                                {processedArticlesTrio2.sectionSubCopy && (
                                    <div className="fm-home-articles-carousel__subtitle">
                                        {processedArticlesTrio2.sectionSubCopy}
                                    </div>
                                )}
                            </div>
                            <FMCarousel
                                instanceName="home-article-carousel-2"
                                isUniMode={
                                    processedArticlesTrio2?.articles?.length < 4
                                }
                                items={CategoryViewUtil.MapArticlesToFMCardProps(
                                    processedArticlesTrio2?.articles ?? []
                                )}
                                render={(item: FMCardProps) => (
                                    <FMCard
                                        {...item}
                                        imageSrc={`${item.imageSrc}`}
                                        ctaOnClick={handleFMCardClick(
                                            item,
                                            'smash-home-service-offers-onclick-event'
                                        )}
                                        cta2OnClick={handleFMCardClick(
                                            item,
                                            ''
                                        )}
                                    >
                                        {item.children ?? ''}
                                    </FMCard>
                                )}
                            />
                        </>
                    )}

                    {disclaimers.length > 0 && (
                        <DisclaimerList disclaimers={disclaimers} />
                    )}
                    {searchContent && helpCardsContent && (
                        <PreImagineEscapeHatch
                            searchContent={searchContent}
                            helpCardsContent={helpCardsContent}
                        />
                    )}
                    <DisclosuresLinkSyndicationAware />
                </div>
            ) : (
                <ActivityIndicator className="full-height" />
            )}
        </>
    );
};
export default HomePagePreImagine;

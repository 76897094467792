import React, { useContext } from 'react';
import { FMListItem } from '@own/fds-react';
import { FindASolutionFragment } from '../../../models/experiencefragments/find-a-solution';
import { useAnalytics } from '../../../hooks/use-analytics';
import './pre-imagine-find-a-solution.scss';
import PreImagineSearchBar from '../search-bar/pre-imagine-search-bar/pre-imagine-search-bar';
import { findByAlias } from '../../../routes';
import { NavLink } from 'react-router-dom';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import ServerContext from '../../../contexts/serverContext';

interface Props {
    content: FindASolutionFragment;
    analyticsEventName?: string;
    title?: any;
}

export const PreImagineFindASolution = (props: Props) => {
    const [fireEvents] = useAnalytics();

    const appConfig = new AppConfigurationService();
    const countryCode = appConfig.current3LetterCountryCode;
    const isUsa = countryCode === 'usa';
    const isCanada = countryCode === 'can';
    const serverContext = useContext(ServerContext);
    const isFord = serverContext.brand.toLowerCase() === 'ford';
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <div className={'links-wrapper'}>
            <h3 className={'links-heading'}> {props.title}</h3>
            <PreImagineSearchBar
                searchContent={props.content.searchContent}
                hideSearchBarTitle={true}
            ></PreImagineSearchBar>
            <div className={'find-a-solution-links'}>
                {props.content?.links?.map(searchLink => {
                    const tileTitle = searchLink.title.includes('Recalls');

                    let recallsSpaTileUrl = null;
                    if (isFord && (isUsa || isCanada)) {
                        recallsSpaTileUrl = findByAlias('TakataRecallsPage');
                    } else {
                        recallsSpaTileUrl = findByAlias('RecallsPage');
                    }

                    return tileTitle ? (
                        <NavLink
                            key={searchLink.url}
                            className="find-a-solution-smart-tiles"
                            title={searchLink.title}
                            to={recallsSpaTileUrl}
                            onClick={() => {
                                scrollToTop();
                                props.analyticsEventName &&
                                    fireEvents(
                                        props.analyticsEventName,
                                        undefined,
                                        {
                                            smashHomeClick: {
                                                articleName: searchLink.title,
                                            },
                                        },
                                        false
                                    );
                            }}
                            type={'no-description'}
                        >
                            <img
                                src={
                                    process.env.REACT_APP_AEM_BASE_URL +
                                    searchLink.icon
                                }
                                height={32}
                                width={32}
                                alt="recalls-tile-icon"
                            />
                            <div className="smart-tiles-content">
                                {searchLink.title}
                            </div>
                        </NavLink>
                    ) : (
                        <FMListItem
                            key={searchLink.url}
                            title={searchLink.title}
                            description={''}
                            imgPath={
                                process.env.REACT_APP_AEM_BASE_URL +
                                searchLink.icon
                            }
                            type={'no-description'}
                            ctaPath={searchLink.url}
                            showChevron={false}
                            onClick={() => {
                                props.analyticsEventName &&
                                    fireEvents(
                                        props.analyticsEventName,
                                        undefined,
                                        {
                                            smashHomeClick: {
                                                articleName: searchLink.title,
                                            },
                                        },
                                        false
                                    );
                            }}
                        ></FMListItem>
                    );
                })}
            </div>
        </div>
    );
};

import React, { useContext } from 'react';
import ServerContext from '../../../contexts/serverContext';
import { CategoriesProps } from '../browse-by-topic/hook/use-bbt-content';
import { Link } from '../../../components/common';
import { useRouterLocation } from '../../../hooks/use-router-location';
import { useBbtCategories } from '../browse-by-topic/hook/use-bbt-categories';
import { BRAND } from '../../../constants';
import './pre-imagine-browse-by-topic.scss';
import { ASTUTE } from '../../support-constants';
import { FMListItem } from '@own/fds-react';
import { useAnalytics } from '../../../hooks/use-analytics';
import ServerSideService from '../../../services/server-side-service/server-side-service';

interface Props {
    bbtContent: CategoriesProps | null;
    analyticsClickEventName?: string;
}

const PreImagineBrowseByTopic = (props: Props) => {
    const [fireEvents] = useAnalytics();
    const { bbtContent } = props;
    const serverContext = useContext(ServerContext);
    const displayCategories: {
        url: string;
        iconPath: string;
        categoryName: string | undefined;
    }[] = [];
    const currentRoot = serverContext.root
        ? serverContext.root.substring(0, serverContext.root.length - 1)
        : '';
    const defaultIconPath =
        serverContext.brand === BRAND.ford.LONG_NAME
            ? 'icons/bbt-desktop-default-icon.svg'
            : 'icons/bbt-desktop-default-lincoln-icon.svg';
    const location = useRouterLocation();
    const curPathName = ServerSideService.isClientSide()
        ? filterXSS(location.pathname)
        : null;

    const bbtCategories = useBbtCategories(
        serverContext.currentLanguageRegionCode,
        serverContext.brand
    );

    const hideSearchBar = ASTUTE.DOMAINS_TO_SKIP_BBT_SEARCH.includes(
        serverContext.domain
    );
    bbtContent?.categories?.forEach(contentCateg => {
        const categoryIcon = bbtCategories?.find(
            cat => parseInt(contentCateg.category) === cat.categoryId
        );
        const url = `${currentRoot}${categoryIcon?.categoryAbsoluteSeo}`;
        const iconPath = contentCateg?.categoryDesktopIcon
            ? `${process.env.REACT_APP_AEM_BASE_URL +
                  contentCateg?.categoryDesktopIcon}`
            : defaultIconPath;
        categoryIcon &&
            displayCategories.push({
                url: url,
                iconPath: iconPath,
                categoryName: categoryIcon?.categoryName,
            });
    });
    bbtCategories?.forEach(element => {
        if (
            displayCategories.findIndex(
                dispCateg => dispCateg.categoryName === element.categoryName
            ) < 0
        ) {
            const url = `${currentRoot}${element?.categoryAbsoluteSeo}`;
            displayCategories.push({
                url: url,
                iconPath: defaultIconPath,
                categoryName: element?.categoryName,
            });
        }
    });

    const handleClick = (category: {
        url: string;
        iconPath: string;
        categoryName: string | undefined;
    }) => {
        return () => {
            props.analyticsClickEventName &&
                fireEvents(
                    props.analyticsClickEventName,
                    undefined,
                    {
                        smashHomeClick: {
                            bbtCategoryName: category.categoryName,
                        },
                    },
                    false
                );
        };
    };

    return bbtContent &&
        bbtCategories &&
        !bbtContent.hideBrowseByTopic &&
        !hideSearchBar ? (
        <div id="bbt">
            <h2 className="bbt-title">
                <Link
                    className="section-heading-link"
                    href={`${curPathName}#bbt`}
                    role="presentation"
                    tabIndex={-1}
                >
                    {bbtContent.titleWithDisclaimer || bbtContent.title}
                </Link>
            </h2>
            <p className="bbt-subtitle">
                {bbtContent.subTitleWithDisclaimer || bbtContent.subTitle}
            </p>
            <ul className="categories">
                {displayCategories?.map(category => {
                    return (
                        <div className={`bbt-fm-list-item`} key={category.url}>
                            <FMListItem
                                title={category.categoryName || ''}
                                imgPath={category.iconPath}
                                type={'no-description'}
                                ctaPath={category.url}
                                showChevron={false}
                                description={''}
                                onClick={handleClick(category)}
                            />
                        </div>
                    );
                })}
            </ul>
        </div>
    ) : (
        <></>
    );
};
export default PreImagineBrowseByTopic;

import { useContext, useEffect, useState } from 'react';
import ServerContext from '../../../contexts/serverContext';
import { useBreakpoints } from '../../../hooks/use-breakpoints';
import { useExperienceContent } from '../../../hooks/use-server-content';
import { VehicleAttributes } from '../../../models/vehicle-attributes';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { ExperienceFragmentModel } from '../../../services/content-service/content-service.interface';
import {
    SyncMapUpdateResponse,
    VwMapsResponse,
} from '../../../services/sync-service/sync-map-service';
import { useSupportCommonContent } from '../../../support/components/astute-topic/hook/use-support-common';
import { getSyncMapsUpdatesResponse } from './util/get-sync-maps-update-response';
import { getSyncCards } from './util/get-sync-cards';
import { getMapCards } from './util/get-sync-map-cards';
import { getCTRCards } from './util/get-sync-ctr-cards';
import { getVWCards } from './util/get-sync-vw-cards';
import { getSyncAccordionContent } from './util/get-sync-accordion-content';
import { getEuMapAccordionContent } from './util/get-sync-eu-map-accordion-content';
import { getVwAccordionContent } from './util/get-sync-vw-accordion-content';
import { getNaMapAccordionContent } from './util/get-sync-na-map-accordion-content';
import { getCtrAccordionContent } from './util/get-sync-ctr-accordion-content';

export interface SyncMapStatus extends ExperienceFragmentModel {
    hide: boolean;
    syncHeading: string;
    syncIsUpToDate: string;
    syncUpdateAvailable: string;
    syncAutomaticUpdates: string;
    syncUpdateAvailableDescription: string;
    syncUpdateSelectorTitle: string;
    syncCards: SyncMapsCard[];
    mapsCards: SyncMapsCard[];
    ctrCards: SyncMapsCard[];
    vwCards: SyncMapsCard[];
    syncWifiLabel: string;
    syncUsbLabel: string;
    syncDealerLabel: string;
    mapsHeading: string;
    mapsIsUpToDate: string;
    mapsUpdateAvailable: string;
    mapsAutomaticUpdates: string;
    updateSyncFirst: string;
    mapsUpdateAvailableDescription: string;
    notAvailableLabel: string;
    logFileError: string;
    pendingLogFileError: string;
    xmlVinMismatchMessage: string;
    uploadPendingMessage: string;
    updateErrorPendingMessage: string;
    syncApiError: string;
    apiDownloadUrlError: string;
    uploadPendingTitle: string;
    ctrHide: boolean;
    ctrHeading: string;
    ctrIsUpToDate: string;
    ctrUpdateAvailable: string;
    ctrUpdateAvailableDescription: string;
    ctrMapsNotAvailable: string;
    ctrMapsNotAvailableDescription: string;
    vwHide: boolean;
    vwHeading: string;
    vwMapsUpToDate: string;
    vwMapsUpdateAvailable: string;
    vwUpdateAvailableDescription: string;
    vwMapsNotAvailable: string;
    vwMapsNotAvailableDescription: string;
}

export interface SyncMapsCard {
    hide: boolean;
    syncMapsCardType: string;
    syncGeneration: string;
    step: string;
    title: string;
    platformLabel: string;
    osLabel1: string;
    osLabel2: string;
    osLabel1Cta: string;
    osLabel2Cta: string;
    osLabel1Aria: string;
    osLabel2Aria: string;
    optionalLabel2: string;
    optionalLabel2Title: string;
    optionalLabel2Gen3: string;
    optionalLabel2Subtitle: string;
    optionalLabel1: string;
    optionalLabel1Title: string;
    optionalLabel1Subtitle: string;
    optionalLabel3Title: string;
    isMobileCard: boolean;
    isTooltip: boolean;
    tooltipOSLabel1Content: string;
    tooltipOSLabel2Content: string;
    tooltipAriaLabel: string;
    main: string;
    isLogFileInput: boolean;
    ctaLabel1: string;
    ctaLabel2: string;
    ctaLabel1Aria: string;
    ctaLabel2Aria: string;
    cta2: string;
    cta1: string;
    footerDescription: string;
}

export interface SyncMapAccordionContent {
    selectedOption?: string;
    hasSync?: boolean;
    hasVw?: boolean;
    hasCtr?: boolean;
    hasUpdate?: boolean;
    syncGeneration?: string;
    cards: SyncMapsCard[] | [];
    heading: string | undefined;
    title: string | undefined;
    subtitle: string | undefined;
    radioButtons?: boolean;
    upToDate: string | undefined;
    syncMapContent?: SyncMapStatus | null;
    downloadUrl?: string;
    downloadUrlError?: boolean;
    notAvailable: string | undefined;
    accordionType: string | undefined;
    eulaURL?: string;
    version?: boolean;
    error?: string;
    releaseDate?: string;
    dealerOnlyCardExists?: boolean;
    hasTicket?: boolean;
}

interface SyncMapResponse {
    syncMapContent: SyncMapStatus | null;
    syncMapsUpdates: SyncMapsConsolidatedResponse | undefined;
    loading: boolean;
    vinLookupError: string;
    syncGeneration: string;
    syncAccordionContent: SyncMapAccordionContent;
    mapAccordionContent: SyncMapAccordionContent;
    ctrAccordionContent: SyncMapAccordionContent;
    vwAccordionContent: SyncMapAccordionContent;
    vehicleInfo: VehicleAttributes | undefined;
}

export interface SyncMapsConsolidatedResponse {
    syncMapUpdateResponse: SyncMapUpdateResponse;
    vehicleAttributes: VehicleAttributes;
    vwMapsUpdateResponse: VwMapsResponse;
}

export const useSyncMapsStatusUpdates = (
    vin: string,
    syncOption: string,
    mapsOption: string
): SyncMapResponse => {
    const [syncMapContent, setSyncMapContent] = useState<SyncMapStatus | null>(
        null
    );
    const [experienceContent] = useExperienceContent<SyncMapStatus>(
        'sync',
        'sync-maps',
        'sync_maps',
        undefined,
        false
    );
    const supportCommonContent = useSupportCommonContent();
    const { desktopOrLarger } = useBreakpoints();
    const isEU = new AppConfigurationService().isRegionEU();
    const [loading, setLoading] = useState<boolean>(false);
    const [vinLookupError, setVinLookupError] = useState<string>('');
    const [vehicleInfo, setVehicleInfo] = useState<VehicleAttributes>();
    const [syncMapsUpdates, setSyncMapsUpdates] = useState<
        SyncMapsConsolidatedResponse
    >();
    const [hasTicket, setHasTicket] = useState<boolean>(false);

    const serverContext = useContext(ServerContext);
    const {
        currentLanguageRegionCode,
        current3LetterCountryCode,
    } = serverContext;

    const syncGeneration =
        syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse?.generation?.toString() ||
        '';

    const syncGenVerSplit =
        syncMapsUpdates?.syncMapUpdateResponse?.syncUpdateResponse?.currentSyncVersion?.split(
            ' '
        ) || '';
    const isVersion34 =
        syncGeneration === '3' &&
        isEU &&
        syncGenVerSplit.length > 1 &&
        syncGenVerSplit[syncGenVerSplit.length - 1]
            .toLowerCase()
            .replace('v', '')
            .substring(0, 3) === '3.4';
    const isSyncOta = syncGeneration === '4' || syncGeneration === '5';

    const syncCards = getSyncCards(
        syncMapContent,
        syncOption,
        syncGeneration,
        desktopOrLarger
    );

    const dealerOnlyCardExists =
        syncMapContent?.syncCards &&
        syncMapContent?.syncCards.filter(
            card => card.syncMapsCardType === 'dealer_only'
        ).length === 1;

    const mapCards = getMapCards(
        syncMapContent,
        mapsOption,
        syncGeneration,
        desktopOrLarger,
        isVersion34
    );

    const ctrCards = getCTRCards(syncMapContent, syncOption, desktopOrLarger);

    const vwCards = getVWCards(syncMapContent, syncOption, desktopOrLarger);

    const syncAccordionContent = getSyncAccordionContent({
        syncOption,
        syncMapsUpdates,
        syncGeneration,
        syncCards,
        syncMapContent,
        dealerOnlyCardExists,
        hasTicket,
        isSyncOta,
    });

    const ctrAccordionContent = getCtrAccordionContent(
        syncOption,
        syncMapsUpdates,
        ctrCards,
        syncMapContent,
        experienceContent,
        syncGeneration
    );

    const releaseDateObject = syncMapsUpdates?.vwMapsUpdateResponse?.releaseDate
        ? new Date(syncMapsUpdates?.vwMapsUpdateResponse?.releaseDate)
        : '';
    const releaseDate = releaseDateObject
        ? `${releaseDateObject.toLocaleDateString(
              serverContext.currentLanguageRegionCode
          )}`
        : '';

    const vwAccordionContent = getVwAccordionContent({
        syncOption,
        vehicleInfo,
        experienceContent,
        syncGeneration,
        vwCards,
        syncMapContent,
        syncMapsUpdates,
        supportCommonContent,
        releaseDate,
    });

    const euMapAccordionContent = getEuMapAccordionContent(
        syncGeneration,
        syncMapsUpdates,
        mapCards,
        syncMapContent,
        mapsOption,
        isSyncOta,
        isVersion34
    );

    const naMapAccordionContent = getNaMapAccordionContent(
        syncGeneration,
        syncMapsUpdates,
        mapCards,
        syncMapContent,
        isSyncOta,
        isEU
    );

    const mapAccordionContent = isEU
        ? euMapAccordionContent
        : naMapAccordionContent;

    useEffect(() => {
        if (vin) {
            getSyncMapsUpdatesResponse({
                vin,
                currentLanguageRegionCode,
                current3LetterCountryCode,
                setLoading,
                setVinLookupError,
                setVehicleInfo,
                setSyncMapsUpdates,
                setHasTicket,
            });
        }
    }, [vin]);

    useEffect(() => {
        if (experienceContent) {
            setSyncMapContent(experienceContent);
        }
    }, [experienceContent]);
    return {
        syncMapContent,
        syncMapsUpdates,
        loading,
        vinLookupError,
        syncGeneration,
        syncAccordionContent,
        mapAccordionContent,
        ctrAccordionContent,
        vwAccordionContent,
        vehicleInfo,
    };
};
